<template>
  <div id="app">
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive"></router-view>
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive"></router-view>
  </div>
</template>

<script>
import Vue from "vue";
import wx from "weixin-js-sdk";
import "../src/assets/css/reset.scss";
import "../src/assets/css/common.scss";
export default {
  data() {
    return {
      terminal: this.$utils.isMobileOrPc(), //判断终端(pc&mobile)
      // terminal: "mobile", //判断终端(pc&mobile)
      isAndroid: this.$utils.isAndroid(), //判断是否安卓手机
      isIphone: this.$utils.isIphone(), //判断是否苹果手机
      bid: "", //判断地址是否带bid参数，商务联系要显示对应参数
      mini: "", //是否有带mini，有则说明在微信小程序里webview显示（是否是小程序进入）
      isMiniprogramEnter: false, //是否是小程序进入
      debug: "",
    };
  },
  created() {
    Vue.prototype.terminal = this.terminal;
    Vue.prototype.isAndroid = this.isAndroid;
    Vue.prototype.isIphone = this.isIphone;
    this.MiniprogramEnter();
  },
  mounted() {
    this.$router.onReady(() => {
      this.getDebug();
      this.getMini();
      this.getBid();
      this.iosSaveEntryUrl();
    });
    // window.addEventListener(
    //   "pageshow",
    //   () => {
    //     alert("pageshow");
    //   },
    //   false
    // );
    document.addEventListener("visibilitychange", () => {
      if (document.visibilityState === "visible") {
        if (!this.mini) {
          window.entryUrl = location.href.split("#")[0];
        }
      }
    });
  },
  methods: {
    // 记录ios手机首次进入url，用于解决苹果手机分享sdk签名报错
    iosSaveEntryUrl() {
      if (typeof window.entryUrl === "undefined" || window.entryUrl === "") {
        window.entryUrl = location.href.split("#")[0];
      }
    },
    getDebug() {
      this.debug = this.$route.query.debug || "";
      if (this.debug) {
        sessionStorage.setItem("debug", this.debug);
      }
    },
    getMini() {
      this.mini = this.$route.query.mini || "";
      if (this.mini) {
        sessionStorage.setItem("mini", this.mini);
      }
    },
    getBid() {
      this.bid = this.$route.query.bid || ""; //是否有带bid，有则说明通过分享过来
      if (this.bid) {
        localStorage.setItem("bid", this.bid);
      }
    },
    // 判断是否小程序进入
    MiniprogramEnter() {
      var ua = navigator.userAgent.toLowerCase();
      if (ua.match(/MicroMessenger/i) == "micromessenger") {
        // 微信环境
        console.log("【查看当前环境】:是微信环境");
        sessionStorage.setItem("isWeChat", true);
        //ios的ua中无miniProgram，但都有MicroMessenger（表示是微信浏览器）
        wx.miniProgram.getEnv((res) => {
          if (res.miniprogram) {
            //小程序环境
            this.isMiniprogramEnter = true;
            sessionStorage.setItem(
              "isMiniprogramEnter",
              this.isMiniprogramEnter
            );
          } else {
            //微信环境
            this.isMiniprogramEnter = false;
          }
          // console.log("isMiniprogramEnter:" + this.isMiniprogramEnter);
        });
      } else {
        // 微信外其他浏览器
        console.log("【查看当前环境】:其他H5环境");
        sessionStorage.setItem("isWeChat", false);
        this.isMiniprogramEnter = false;
      }
    },
  },
};
</script>
<style lang="scss">
</style>
