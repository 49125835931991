import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import "@/common/axios"
import "@/common/utils.js";

// 引入自定义指令
import './directives/lazyBackground';
import './directives/html-image-style';
import './directives/html-video-poster';

// 导入图标库
import "@/assets/fonts/iconfont.css"

//默认头像
import avatar from "@/assets/images/common/avatar.png";
Vue.prototype.defaultAvatar = avatar

//可解决多个message同时显示问题
import { message } from "./common/message"
Vue.prototype.$message = message;

// 地址配置
Vue.prototype.$apiURL = process.env.VUE_APP_API_BASE_URL
Vue.prototype.$webURL = process.env.VUE_APP_WEB_URL

// 时间处理
import moment from 'moment'//导入文件
Vue.prototype.$moment = moment;//赋值使用

// 路由query合并
import merge from "webpack-merge";
Vue.prototype.$merge = merge;

// 图片懒加载
import VueLazyload from 'vue-lazyload'
// 配置项
Vue.use(VueLazyload, {
  preLoad: 1.3,
  attempt: 1
})


// element-ui（按需引入）
import 'element-ui/lib/theme-chalk/index.css';
import { Pagination, Input, Dialog, Empty, Loading, Image, Tooltip } from 'element-ui';
Vue.use(Pagination);
Vue.use(Input);
Vue.use(Dialog);
Vue.use(Empty);
Vue.use(Loading);
Vue.use(Image);
Vue.use(Tooltip);

// vant（按需引入）
import '@vant/touch-emulator'; //pc端支持vant，一些手机触摸等事件转化为pc支持的事件
// import { Tabs, Tab, Dialog as VanDialog, Toast, Popup } from 'vant' // 引入Button组件
import { Tabs, Tab, Toast, Popup, Sticky, List, Search, Field, Button, Form, CountDown } from 'vant' // 引入组件
import 'vant/lib/index.css';
Vue.use(Tabs)
Vue.use(Tab)
// Vue.use(VanDialog)
Vue.use(Toast)
Vue.use(Popup)
Vue.use(Sticky)
Vue.use(List)
Vue.use(Search)
Vue.use(Field)
Vue.use(Button)
Vue.use(Form)
Vue.use(CountDown)

// 全局注册 
// import Vant from "vant"
// import 'vant/lib/index.css';
// Vue.use(Vant)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
